import $ from 'jquery';

import ChairishHistory from 'chairisher/util/history';

import {
    AmplitudeEventProperty,
    logAmplitudeEvent,
    logAmplitudeLinkClickEvent,
    logScreenView,
    TrackingScreen,
} from 'chairisher/util/analytics';
import {
    getCollectionCanonicalTerm,
    getCollectionHandle,
    getCollectionId,
    hasExtendedNav,
    isCollectionCurated,
    isFolder,
} from 'chairisher/context/collection';
import { getNumResults } from 'chairisher/context/search';

/**
 * Gets the redirect type from the URL and cleanses the param.
 *
 * @returns {string|null}
 */
export function getRedirectType() {
    const redirectTypeParam = 'redirect_type';
    const params = new URLSearchParams(window.location.search);
    const redirectType = params.get(redirectTypeParam);
    if (redirectType) {
        // Cleanse so that this isn't tracked again on a refresh.
        ChairishHistory.cleanseParamFromUri(redirectTypeParam);
    }
    return redirectType;
}

export function trackCollectionScreenView() {
    logScreenView(TrackingScreen.COLLECTION, {
        [AmplitudeEventProperty.CANONICAL_TERM]: getCollectionCanonicalTerm(),
        [AmplitudeEventProperty.COLLECTION_HANDLE]: getCollectionHandle(),
        [AmplitudeEventProperty.COLLECTION_ID]: getCollectionId(),
        [AmplitudeEventProperty.HAS_VISUAL_NAV]: hasExtendedNav(),
        [AmplitudeEventProperty.IS_CURATED]: isCollectionCurated(),
        [AmplitudeEventProperty.IS_FOLDER]: isFolder(),
        [AmplitudeEventProperty.NUM_RESULTS]: getNumResults(),
        [AmplitudeEventProperty.REDIRECT_TYPE]: getRedirectType(),
    });
}

/**
 * Track a click event on the pagination elements on the Search page
 * @param {Object} e The event fired
 * @param {string} position The position of the pagination element
 */
export function trackPaginationClick(e, position) {
    const $link = $(e.currentTarget);
    logAmplitudeLinkClickEvent(`page: ${$link.text()}`.trim(), $link.attr('href'), position);
}

/**
 * Tracks a sort action for search
 * @param {string=} sortValue
 */
export function trackSearchSorting(sortValue = 'relevance') {
    logAmplitudeEvent('browse - sort', {
        'sort option': sortValue,
    });
}

/**
 * Tracks a search refinement
 *
 * @param {string} refinementName The name of the refinement that was selected
 * @param {string} refinementValue The current value of the refinement that was selected
 * @param {Array|string} searchStateValues All values (if there are more than one) selected for the given refinement
 * @param {null|string} position The refinement's position (ok to omit when not relevant).
 */
export function trackSearchRefinement(refinementName, refinementValue, searchStateValues, position = null) {
    const stateValue = Array.isArray(searchStateValues) ? searchStateValues.join(',') : searchStateValues;

    logAmplitudeEvent('browse - refine', {
        [refinementName.toLowerCase()]: stateValue || refinementValue,
        [AmplitudeEventProperty.POSITION]: position,
    });
}
