import $ from 'jquery';

import MediaQueryUtils from 'chairisher/util/mediaquery';

import { hasIntersectionObserver, observeIntersectionOnce } from 'chairisher/view/helper/intersectionobserver';

const ImageHelper = {};

const _lazyLoadingNamespace = 'lazyload';
const lazyLoadSelector = '[data-lazy-load=true]';

/**
 * Lazily loads an image based on its `srcset` attribute and the screen size.
 * Executes a success callback that is chained to the return value using the `.done` interface via jQuery.Deferred.
 *
 * @returns {jQuery.Deferred}
 *
 * @see https://api.jquery.com/jquery.deferred/
 */
ImageHelper.lazyLoadImages = function () {
    let windowHeight = window.innerHeight;
    let windowWidth = window.innerWidth;

    const $window = $(window);

    const _lazyLoad = function () {
        const $elements = $(lazyLoadSelector);

        if ($elements.length) {
            let visibleCount = 0;

            $elements.each((i, placeholderElement) => {
                const $el = $elements.eq(i);
                const eligibleMediaConditions = $el.data('media');

                if (!MediaQueryUtils.matchesCurrentMedia(eligibleMediaConditions)) {
                    return;
                }

                const rect = placeholderElement.getBoundingClientRect();

                const isVisible =
                    rect.top < windowHeight &&
                    rect.top + rect.height > 0 &&
                    rect.left < windowWidth &&
                    rect.left + rect.width > 0 &&
                    placeholderElement.offsetParent !== null;

                if (isVisible) {
                    ImageHelper.loadLazyImage(placeholderElement);
                    visibleCount++;
                }
            });
        } else {
            // Since there is nothing left to load detach the event handlers
            ImageHelper.unbindLazyImageLoading();
        }
    };

    if (hasIntersectionObserver()) {
        observeIntersectionOnce(lazyLoadSelector, (entry) => {
            ImageHelper.loadLazyImage(entry.target);
        });
    } else {
        // lazy load images based on viewport size
        $window.smartresize(() => {
            windowHeight = window.innerHeight;
            windowWidth = window.innerWidth;
            _lazyLoad();
        }, _lazyLoadingNamespace);

        // lazy load images based on visibility in viewport
        $window.smartscroll(_lazyLoad, _lazyLoadingNamespace);

        _lazyLoad();
    }

    // lazy load descendants on hover
    $('[data-lazy-load-hover]').one('mouseenter', function () {
        ImageHelper.lazyLoadDescendants($(this));
    });
};

/**
 * Unbinds all events from `window` related to lazy image loading
 */
ImageHelper.unbindLazyImageLoading = function () {
    $(window).off(`scroll.${_lazyLoadingNamespace}`).off(`resize.${_lazyLoadingNamespace}`);
};

/**
 * Loads an image marked to be loaded lazily
 * @param {HTMLElement} element The image to load
 */
ImageHelper.loadLazyImage = function (element) {
    // remove the data attribute so we don't risk trying to load the same image twice
    const newSrcset = element.getAttribute('data-srcset');
    if (newSrcset) {
        element.srcset = newSrcset;
        element.removeAttribute('data-lazy-load');
        element.removeAttribute('data-srcset');
    }
};

/**
 * Loads a jQuery element's images that are marked t be lazily loaded
 * @param {jQuery} $el
 */
ImageHelper.lazyLoadDescendants = function ($el) {
    const $lazyLoadImages = $el.find(lazyLoadSelector);
    $lazyLoadImages.each((i, el) => {
        ImageHelper.loadLazyImage(el);
    });
};

export default ImageHelper;
