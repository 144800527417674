import $ from 'jquery';

/**
 * A choice that may or may not contain sub-choices
 * @typedef {Object} Choice
 * @property {string} display
 * @property {string|number} value
 * @property {Object} [image]
 * @property {string} [image.src]
 * @property {string} [image.srcset]
 * @property {string} [image.sizes]
 * @property {string} [url]
 */

/**
 * Widget that displays choices as a pill, that can be grouped together or not, and deselected at will
 *
 * @param {Object} settings
 * @param {Pill.AddOnType} settings.dataAddOnType The add-on type that should be rendered with this pill
 * @param {string} settings.dataDisplayKey The key in the Choice data whose value should be displayed
 * @param {Choice} settings.value The Choice data to be visualized by this component
 *
 * @class Pill
 * @constructs Pill
 */
const Pill = function (settings) {
    settings = $.extend(
        {
            dataAddOnType: this._dataAddOnType,
            dataDisplayKey: this._dataDisplayKey,
            value: this._value,
        },
        settings,
    );

    this._value = settings.value;
    this._dataAddOnType = settings.dataAddOnType;
    this._dataDisplayKey = settings.dataDisplayKey || 'display';

    this._$el = this._marshalPillMarkup();
    this._$el.data('pill', this);
    this._bind();
};

/**
 * Values that can be used to indicate ui elements that should be added on to the pill based on its value
 *
 * @enum
 */
Pill.AddOnType = {
    SWATCH: 'swatch',
};

/**
 * jQuery element representing a pill
 *
 * @type {jQuery}
 * @private
 */
Pill.prototype._$el = null;

/**
 * Indicates if an add on (like a color swatch) should be appended to pill based on its value
 *
 * @type {Pill.AddOnType}
 * @private
 */
Pill.prototype._dataAddOnType = null;

/**
 * The key found in a choice whose value should be displayed
 *
 * @type {string}
 * @private
 * @default
 */
Pill.prototype._dataDisplayKey = 'display';

/**
 * String used to segregate events related to the pill into a separate namespace
 *
 * @type {string}
 * @private
 * @default
 */
Pill.prototype._eventNamespace = 'pill';

/**
 * Collection of Choice objects to display
 *
 * @type {Choice}
 * @private
 */
Pill.prototype._value = null;

/**
 * Binds actions to the various elements that comprise a pill
 *
 * @private
 */
Pill.prototype._bind = function () {
    // clicking anywhere on the pill removes it
    this._$el.on(
        'mousedown',
        $.proxy(function (e) {
            e.preventDefault();
            e.stopPropagation();
            if (this.isEnabled()) {
                this.remove();
            }
        }, this),
    );
};

/**
 * Disables the Pill so it cannot be removed
 */
Pill.prototype.disable = function () {
    this._$el.addClass('disabled');
};

/**
 * Enables the Pill so it can be removed
 */
Pill.prototype.enable = function () {
    this._$el.removeClass('disabled');
};

/**
 * @returns {jQuery} The jQuery pill element
 */
Pill.prototype.getEl = function () {
    return this._$el;
};

/**
 * @returns {Choice} The value of the Pill
 */
Pill.prototype.getValue = function () {
    return this._value;
};

/**
 * @returns {boolean} Flag indicating if the Pill is enabled and can be removed
 */
Pill.prototype.isEnabled = function () {
    return !this._$el.hasClass('disabled');
};

/**
 * Removes the pill element from the DOM and triggers an event so listeners can react
 */
Pill.prototype.remove = function () {
    this._$el.trigger([this._eventNamespace, 'remove'].join(':'), [this._value]);
    this._$el.remove();
};

/**
 * Create a new jQuery representation of a pill
 *
 * @private
 */
Pill.prototype._marshalPillMarkup = function () {
    const $pill = $('<span></span>', {
        class: 'pill js-pill',
    });

    const choice = this._value;
    const { value } = choice;

    const $pillText = $('<span></span>', {
        class: 'pill-text',
        text: choice[this._dataDisplayKey] || value,
    });

    const $ciconX = $('<span></span>', {
        class: 'cicon cicon-x js-dismiss',
    });

    const $pillValue = $('<span></span>', {
        class: 'pill-value',
    });

    $pillValue.append($pillText, $ciconX);

    if (this._dataAddOnType === Pill.AddOnType.SWATCH) {
        $pillValue.prepend(
            $('<span></span>', {
                class: 'swatch',
                style: `background-color: #${value}`,
            }),
        );
    }

    $pill.append($pillValue);
    $pill.attr('data-value', value);

    return $pill;
};

export default Pill;
