import $ from 'jquery';

import AccountContext from 'chairisher/context/account';
import AlerterView from 'chairisher/view/alerter';
import AuthContext from 'chairisher/context/auth';
import CookieUtils from 'chairisher/util/cookie';
import CookiesContext from 'chairisher/context/cookies';
import LocationContext from 'chairisher/context/location';
import PaypalStoreOffer from 'chairisher/thirdparty/paypalstoreoffer';
import ToggleView from 'chairisher/view/toggle';

import trackCookieSettingChange from 'chairisher/analytics/privacy';

const ChairishContext = window.chairisher.context;

/**
 * @class CookieBannerView
 * @constructs CookieBannerView
 */
class CookieBannerView {
    constructor() {
        /**
         * Flag indicating whether the user has made any changes to cookies and to show the alert for that
         *
         * @type {boolean}
         */
        this.isChanged = false;

        /**
         * @type {jQuery}
         */
        this.$cookieBanner = $('#js-cookie-banner');

        /**
         * @type {jQuery}
         */
        this.$cookieBannerModal = $('#js-cookie-banner-modal');

        /**
         * Flag indicating whether the user has made any changes to cookies and to show the alert for that
         *
         * @type {boolean}
         */
        this.isChanged = false;

        /**
         * Queue of settings dictionaries to make ajax calls with which update a user's cookie settings
         *
         * @type {array}
         */
        this.settingsToggleQueue = [];

        /**
         * @enum {string}
         */
        this.toggleIdToCookieMap = {
            'js-functional-cookies-toggle': CookiesContext.getFunctionalCookiesCode(),
            'js-performance-cookies-toggle': CookiesContext.getPerformanceCookiesCode(),
            'js-advertising-cookies-toggle': CookiesContext.getAdvertisingCookiesCode(),
        };
        const cookieDismissedLocation = CookieUtils.getValueFromCookie(CookiesContext.getCookieBannerCode());
        const isCookieBannerDismissed = !!cookieDismissedLocation;
        const isCookiePreferencesSet = !!CookieUtils.getValueFromCookie(CookiesContext.getCookiesPreferenceCode());

        this.shouldShowBanner(isCookieBannerDismissed, isCookiePreferencesSet);

        // synch users who have moved domains
        // US users with temporary dismissal get permanent dismissal
        if (
            isCookieBannerDismissed &&
            !isCookiePreferencesSet &&
            LocationContext.isUS() &&
            AuthContext.isAuthenticated()
        ) {
            this.optOut(true);
            // NON-US users who dismissed while anonymous get the banner again
        } else if (
            !LocationContext.isUS() &&
            cookieDismissedLocation === LocationContext.countryCodes.anonymous &&
            AuthContext.isAuthenticated()
        ) {
            this.unOptOut();
        }

        this.$cookieBanner.on('click', '.js-cookie-settings-btn', (e) => {
            e.preventDefault();
            this.showModal();
        });

        this.$cookieBannerModal.on('click', '.js-done-btn', () => {
            for (const toggleIdToCookieMapKey in this.toggleIdToCookieMap) {
                if ({}.hasOwnProperty.call(this.toggleIdToCookieMap, toggleIdToCookieMapKey)) {
                    const cookieCode = this.toggleIdToCookieMap[toggleIdToCookieMapKey];
                    const $btnToggle = $(`#${toggleIdToCookieMapKey}`);
                    const isOff = !$btnToggle.hasClass($btnToggle.data('active-class'));
                    const isOptedOut = CookiesContext.isOptedOutOfCookie(cookieCode);
                    if (isOff !== isOptedOut) {
                        this.updateSetting(cookieCode, isOff);
                    }
                }
            }
            this.optOut(true);
            this.hideBanner();
            this.trigger('close');
            this.$cookieBannerModal.modal('hide');
        });

        const $btnToggles = this.$cookieBannerModal.find('.js-btn-toggle');
        $btnToggles.each((i) => {
            const $btnToggle = $btnToggles.eq(i);
            const toggleView = new ToggleView({
                $toggleEl: $btnToggle,
            });

            $btnToggle.on('click', (e) => {
                e.preventDefault();
                toggleView.toggle();
                const cookieCode = this.toggleIdToCookieMap[$btnToggle.attr('id')];
                const isOptedOut = !$btnToggle.hasClass($btnToggle.data('active-class'));
                this.updateSetting(cookieCode, isOptedOut);
            });
        });

        this.$cookieBanner.on('click', '.js-ok-btn', (e) => {
            e.preventDefault();
            this.hideBanner();
            this.optOut(false);
            this.trigger('close');
        });

        $('.js-manage-cookies-link').on('click', () => {
            this.showModal();
        });

        this.$cookieBannerModal.on('hidden.bs.modal', () => {
            if (this.isChanged) {
                AlerterView.success(ChairishContext.COOKIE_PREFERENCES_UPDATED_TEXT);
                this.isChanged = false;
            }
        });
    }

    /**
     * Determines whether banner should show
     *
     * @param {boolean} isCookieBannerDismissed
     * @param {boolean} isCookiePreferencesSet
     */
    shouldShowBanner(isCookieBannerDismissed, isCookiePreferencesSet) {
        if (
            !isCookieBannerDismissed && // session dismissal
            !isCookiePreferencesSet && // permanent dismissal
            !document.referrer.startsWith(document.location.origin) // Check if site is coming from chairish
        ) {
            this.showBanner();
        }
    }

    /**
     * @returns {jQuery}
     */
    getCookieBanner() {
        return this.$cookieBanner;
    }

    /**
     * Updates individual privacy setting
     *
     * @param {string} cookieCode
     * @param {boolean} isOptedOut
     */
    updateSetting(cookieCode, isOptedOut) {
        CookiesContext.setIsOptedOutOfCookie(cookieCode, isOptedOut);
        this.isChanged = true;

        trackCookieSettingChange(cookieCode, isOptedOut);

        if (cookieCode === CookiesContext.getAdvertisingCookiesCode() && isOptedOut) {
            PaypalStoreOffer.excludeUser(isOptedOut);
        }

        if (AuthContext.isAuthenticated()) {
            this.settingsToggleQueue.push({
                cookie: cookieCode,
                is_opted_out: isOptedOut,
            });
            if (this.settingsToggleQueue.length === 1) {
                this.submitSettingsToggleQueue();
            }
        }
    }

    /**
     * Hides the banner element and sets a cookie to opt the user out of seeing that
     */
    hideBanner() {
        this.toggleBanner(false);
    }

    /**
     * Show the banner element
     */
    showBanner() {
        this.toggleBanner(true);
    }

    /**
     * Toggle the banner element
     *
     * @param {boolean} shouldShow
     */
    toggleBanner(shouldShow) {
        this.$cookieBanner.toggleClass('hidden', !shouldShow);
    }

    /**
     * True indicates that the cookie banner is visible
     *
     * @returns {boolean}
     */
    isVisible() {
        return !this.$cookieBanner.hasClass('hidden');
    }

    /**
     * Show the modal element and set the cookie switches to on/off according to the current cookies
     */
    showModal() {
        const $btnToggles = this.$cookieBannerModal.find('.js-btn-toggle');
        $btnToggles.each((i) => {
            const $btnToggle = $btnToggles.eq(i);
            const isOptedOut =
                CookieUtils.getValueFromCookie(this.toggleIdToCookieMap[$btnToggle.attr('id')]) ===
                CookiesContext.getOptOutOfCookiesValue();
            // setting is not specifically disabled and either preferences have been set or in US
            const isOff = !(
                !isOptedOut &&
                (CookiesContext.isOptedOutOfCookie(CookiesContext.getCookiesPreferenceCode()) || LocationContext.isUS())
            );
            $btnToggle.toggleClass('btn-toggle-on', !isOff);
            $btnToggle.toggleClass('btn-toggle-off', isOff);
        });
        this.$cookieBannerModal.modal();
    }

    /**
     * Sets a session cookie that opts the user out of seeing the banner
     * Sets a permanent flag for US or if from modal
     *
     * @param {boolean} isPermanent
     */
    optOut(isPermanent = false) {
        const cookiesPreferenceCode = CookiesContext.getCookiesPreferenceCode();
        CookieUtils.setCookie(
            CookiesContext.getCookieBannerCode(),
            AuthContext.isAuthenticated()
                ? LocationContext.getPrimaryCountry()
                : LocationContext.countryCodes.anonymous,
        );
        if (isPermanent || LocationContext.isUS()) {
            CookieUtils.setCookie(cookiesPreferenceCode, CookiesContext.getOptOutOfCookiesValue(), 365);
            this.updateSettings(cookiesPreferenceCode, true);
        }
    }

    /**
     * Removes a permanent flag that opts the user out of seeing the banner
     */
    unOptOut() {
        const cookiesPreferenceCode = CookiesContext.getCookiesPreferenceCode();
        const cookiesBannerCode = CookiesContext.getCookieBannerCode();
        CookieUtils.removeCookie(cookiesPreferenceCode);
        CookieUtils.removeCookie(cookiesBannerCode);
        this.updateSettings(cookiesPreferenceCode, false, () => {
            this.showBanner();
        });
    }

    /**
     * Triggers a new event while passing along any other additional data provided
     *
     * @param {string} type The new event's type
     */
    trigger(type) {
        this.getCookieBanner().trigger($.Event(`cookiebanner:${type}`));
    }

    /**
     * Checks the settingsToggleQueue for a setting to call ajax with
     * and then shifts that setting out of the queue.
     * If there is another setting in the queue, recurse until the queue is empty.
     */
    submitSettingsToggleQueue() {
        const ajaxData = this.settingsToggleQueue[0];
        this.updateSettings(ajaxData.cookie, ajaxData.is_opted_out, () => {
            this.settingsToggleQueue.shift();
            if (this.settingsToggleQueue.length) {
                this.submitSettingsToggleQueue();
            }
        });
    }

    /**
     * Persist settings to DB.
     * @param {boolean} cookiesPreferenceCode
     * @param {boolean} isOptedOut
     * @param {Function} callback
     */
    updateSettings(cookiesPreferenceCode, isOptedOut, callback = () => {}) {
        if (AuthContext.isAuthenticated()) {
            $.post(
                AccountContext.getCookieSettingsUpdateUrl(),
                {
                    cookie: cookiesPreferenceCode,
                    is_opted_out: isOptedOut,
                },
                callback,
            );
            return;
        }
        callback();
    }
}

export default CookieBannerView;
