import $ from 'jquery';

/**
 * Component that handles the "back to top" scroll and resize logic
 */
const BackToTop = {
    /**
     * Binds all necessary events to make the "back to top" button work
     */
    bind() {
        // back to top
        const $backToTop = $('.js-back-to-top');
        $backToTop.off('click').on('click', (e) => {
            e.preventDefault();
            $('html,body').animate({ scrollTop: 0 }, 'slow');
        });

        const $masthead = $('.js-masthead-container');
        const $crossSiteNav = $('.js-admin-nav', '#js-promo-banner');
        const $productGrid = $('.js-product-grid');

        const getPaginationTopOffset = function () {
            const offsetTop = $productGrid.length ? $productGrid.offset().top : 0;
            return offsetTop + $productGrid.height() - $masthead.height() - ($crossSiteNav.height() || 0) - 10;
        };

        let paginationTopOffset = getPaginationTopOffset();
        const paginationEventNamespace = 'pagination';

        // reset bindings...
        const $window = $(window);
        $window.off(`scroll.${paginationEventNamespace}`);
        $window.off(`resize.${paginationEventNamespace}`);

        // affix pagination and display "back to top"
        $window.smartscroll(() => {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

            const isBackToTopVisible = $backToTop.hasClass('opaque');
            const innerHeight = window.innerHeight - $masthead.height();

            if (!isBackToTopVisible && innerHeight <= scrollTop) {
                $backToTop.addClass('opaque');
            } else if (isBackToTopVisible && innerHeight > scrollTop) {
                $backToTop.removeClass('opaque');
            }
        }, paginationEventNamespace);

        $window.smartresize(() => {
            paginationTopOffset = getPaginationTopOffset();
        }, paginationEventNamespace);
    },
};

export default BackToTop;
