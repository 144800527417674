const ChairishContext = window.chairisher.context;

/**
 * An interface that provides documentation and access to context variables related to zendesk
 */
const ZendeskContext = {
    /**
     * @returns {string|undefined} Optional tag used for help suggestions in the livechat menu
     */
    getLiveChatHelpSuggestionTag() {
        return ChairishContext.LIVECHAT_HELP_SUGGESTION_TAG;
    },

    /**
     * @returns {object} Optional custom settings for the zendesk webWidget
     */
    getLiveChatSettings() {
        return ChairishContext.LIVECHAT_SETTINGS;
    },
};

export default ZendeskContext;
