import $ from 'jquery';
import FolderCardMenu, { FolderCardTransitionAnimations } from 'chairisher/component/card/foldercardmenu';
import Pill from 'chairisher/component/pill';
import ErrorContext from 'chairisher/context/error';
import ProductContext from 'chairisher/context/product';
import AlerterView from 'chairisher/view/alerter';
import SpotlightTypesView from 'chairisher/view/product/spotlighttypes';

import { getCollectionId, getCollectionSortDeleteUrl, getCollectionSortPatchUrl } from 'chairisher/context/collection';
import { FolderCardPosition } from 'chairisher/util/analytics';

/**
 * View that allows for the manipulation of a GridProduct.
 *
 * @param {Object} settings
 * @param {jQuery} settings.$el The jQuery representation of the product
 * @param {GridProductData} settings.data The product's data from the backend
 *
 * @class GridProductView
 * @constructs GridProductView
 */
const GridProductView = function (settings) {
    settings = $.extend(
        {
            $el: this._$el,
            data: this._data,
        },
        settings,
    );

    this._$el = settings.$el;
    this._data = settings.data;
    this._pendingSpotlightData = [];

    this._spotlightTypesView = new SpotlightTypesView({
        $el: this._$el.find('.js-quick-buttons'),
        data: this._data,
    });

    this._bind();
    this._$el.data('gridProductView', this);
};

/**
 * The jQuery representation of the product
 *
 * @type {jQuery}
 * @private
 */
GridProductView.prototype._$el = null;

/**
 * Flag indicating if the spotlight type buttons are enabled or not
 *
 * @type {boolean}
 * @private
 */
GridProductView.prototype._areSpotlightTypeButtonsDisabled = false;

/**
 * Instance of a FolderCardMenu used to add the product to a folder
 *
 * @type {FolderCardMenu}
 * @private
 */
GridProductView.prototype.folderCardMenu = null;

/**
 * The data used to represent/marshal a product
 *
 * @type {Object}
 * @private
 */
GridProductView.prototype._data = null;

/**
 * View that controls a products' spotlight type buttons
 *
 * @type {SpotlightTypesView}
 * @private
 */
GridProductView.prototype._spotlightTypesView = null;

/**
 * Sends data to the server to add this product to the end of the collection sort
 *
 * @returns {$.Deferred} The jQuery Deferred object to chain upon
 * @private
 */
GridProductView.prototype.addToCollectionSort = function () {
    const $btn = this._$el.find('.js-append-to-collection');

    $btn.addClass('disabled');

    return $.ajax({
        data: {
            collection_ids: getCollectionId(),
            product_id: this.getProductId(),
        },
        method: 'POST',
        url: getCollectionSortPatchUrl(),
    })
        .done(
            $.proxy(function (data) {
                if (data.errors && data.errors.length) {
                    AlerterView.error(data.errors[0].message);
                } else {
                    $btn.removeClass('off');
                    $btn.addClass('on');
                    this.setIsMerched(true);
                    this._trigger('change', this);
                }
            }, this),
        )
        .fail((jqXHR) => {
            const data = jqXHR.responseJSON;
            if (data) {
                if (!data.errors || !data.errors.length) {
                    data.errors = [{ message: ErrorContext.getDefaultErrorText() }];
                }
                for (let i = 0; i < data.errors.length; i++) {
                    AlerterView.error(data.errors[i].message);
                }
            } else {
                AlerterView.error();
            }
        })
        .always(() => {
            $btn.removeClass('disabled');
        });
};

/**
 * Disables the spotlight type buttons for this product
 */
GridProductView.prototype.disableSpotlightTypeButtons = function () {
    this._spotlightTypesView.disableSpotlightTypeButtons();
};

/**
 * Enables the spotlight type buttons for this product
 */
GridProductView.prototype.enableSpotlightTypeButtons = function () {
    this._spotlightTypesView.enableSpotlightTypeButtons();
};

/**
 * @returns {Object} The product's data from the backend
 */
GridProductView.prototype.getData = function () {
    return this._data;
};

/**
 * @returns {jQuery} The jQuery object representing the grid product
 */
GridProductView.prototype.getEl = function () {
    return this._$el;
};

/**
 * @returns {jQuery} The event trigger element
 */
GridProductView.prototype.getEventTriggerEl = function () {
    return this._$el.find('.js-event-trigger');
};

/**
 * @returns {number} The id of the product the GridProductView represents
 */
GridProductView.prototype.getProductId = function () {
    return this._data.id;
};

/**
 * Removes the folder trigger from the product's ui
 *
 * @param {boolean=} opt_shouldRemoveImmediately Optional flag indicating the folder trigger should be
 *   removed immediately without waiting for the menu to be closed
 */
GridProductView.prototype.removeFolderTrigger = function (opt_shouldRemoveImmediately) {
    if (opt_shouldRemoveImmediately) {
        if (this.folderCardMenu) {
            this.folderCardMenu.removeFolderTrigger();
            this.folderCardMenu.close();
        }
    } else if (this.folderCardMenu) {
        this.folderCardMenu.close().done(
            $.proxy(function () {
                this.folderCardMenu.removeFolderTrigger();
            }, this),
        );
    }
};

/**
 * Removes spotlight type pills from the product's ui
 */
GridProductView.prototype.removeSpotlightTypePills = function () {
    this._$el.find('.js-tag-container').html('');
};

/**
 * Removes the event trigger from the DOM
 */
GridProductView.prototype.removeEventTrigger = function () {
    this._$el.find('.js-event-trigger').remove();
};

/**
 * Adds a tap target that triggers the add to folder action
 */
GridProductView.prototype.renderFolderTrigger = function () {
    if (ProductContext.canRenderFolderIcons()) {
        this.folderCardMenu = new FolderCardMenu({
            productId: this.getProductId(),
            analyticsTrackingPosition: FolderCardPosition.PRODUCT_GRID,
            animation: FolderCardTransitionAnimations.flip,
            shouldCloseOnSelection: true,
            $appendTo: this.getEl().find('.js-grid-product-menu-wrapper'),
        });
        this.folderCardMenu.marshalFolderTrigger().insertBefore(this.getEl().find('.js-favorite-button'));
        this.folderCardMenu.bind();
    }
};

/**
 * Adds a target that emits a given event when clicked/tapped.
 *
 * @param {string} eventToEmit The name of the event to emit when the event trigger is clicked/tapped
 */
GridProductView.prototype.renderEventTrigger = function (eventToEmit) {
    const $eventTrigger = $('<div></div>', {
        class: 'event-trigger js-event-trigger',
        text: '+',
    });

    $eventTrigger.insertBefore(this._$el.find('.js-favorite-button'));

    $eventTrigger.on(
        'click',
        $.proxy(function (e) {
            e.preventDefault();
            e.stopPropagation();

            this._$el.trigger($.Event(eventToEmit), this);
        }, this),
    );
};

/**
 * Renders the spotlight type buttons for this product based on the product's data
 */
GridProductView.prototype.renderSpotlightTypeButtons = function () {
    this._spotlightTypesView.renderSpotlightTypeButtons();
};

/**
 * Renders the spotlight type wholesale assortment tag for this product based on the product's data
 */
GridProductView.prototype.renderSpotlightTypeTag = function () {
    this._spotlightTypesView.renderSpotlightTypeTag();
};

/**
 * Renders the Add to Top-N button on the product if possible
 */
GridProductView.prototype.renderAddToCollectionSortAction = function () {
    if (getCollectionSortPatchUrl()) {
        let $sortButtonsContainer = this._$el.find('.js-collection-sort-buttons');
        if (!$sortButtonsContainer.length) {
            $sortButtonsContainer = $('<div></div>', {
                class: 'collection-sort-buttons js-collection-sort-buttons',
            });
            this._$el.find('.js-merch-buttons').append($sortButtonsContainer);
        }

        const $btn = $('<span></span>', {
            class: ['spotlight-button', 'js-append-to-collection', this.isMerched() ? 'on' : 'off'].join(' '),
        }).append(
            $('<span></span>', { class: 'cicon cicon-top96-stroke off' }),
            $('<span></span>', { class: 'cicon cicon-top96-fill on' }),
        );

        $sortButtonsContainer.html($btn);
    }
};

/**
 * Renders spotlight type pills on a grid product for easy removal
 */
GridProductView.prototype.renderSpotlightTypePills = function () {
    const tags = [];

    for (let i = 0; i < this._data.spotlight_types.length; i++) {
        tags.push(
            new Pill({
                value: this._data.spotlight_types[i],
            }).getEl(),
        );
    }

    let $tagContainer = this._$el.find('.js-tag-container');
    if (!$tagContainer.length) {
        $tagContainer = $('<div></div>', {
            class: 'tag-container js-tag-container',
        });
        this._$el.find('.js-product-wrapper').prepend($tagContainer);
    }

    $tagContainer.html(tags);
    this._bindPills();
};

/**
 * @returns {boolean} True indicates the product is merch'd in the Top-N for the current collection
 */
GridProductView.prototype.isMerched = function () {
    return !!this._data.is_merched;
};

/**
 * Sends data to the server to remove this product from the collection sort
 *
 * @returns {$.Deferred} The jQuery Deferred object to chain upon
 * @private
 */
GridProductView.prototype.removeFromCollectionSort = function () {
    const $btn = this._$el.find('.js-append-to-collection');

    $btn.addClass('disabled');

    return $.ajax({
        data: {
            collection_id: getCollectionId(),
            product_id: this.getProductId(),
        },
        method: 'POST',
        url: getCollectionSortDeleteUrl(),
    })
        .done(
            $.proxy(function (data) {
                if (data.errors) {
                    AlerterView.error(data.errors[0].message);
                } else {
                    $btn.addClass('off');
                    $btn.removeClass('on');

                    this.setIsMerched(false);
                    this._trigger('change', this);
                }
            }, this),
        )
        .fail(() => {
            AlerterView.error(ErrorContext.getDefaultErrorText());
        })
        .always(() => {
            $btn.removeClass('disabled');
        });
};

/**
 * @param {boolean} isMerched True indicates the product is merch'd in the Top-N for the current collection
 */
GridProductView.prototype.setIsMerched = function (isMerched) {
    this._data.is_merched = isMerched;
};

/**
 * Updates the product's spotlight type data based on the data provided and the action (toggle by default) being
 * taken.
 *
 * Please note that there is a delay in when the data is sent to the server so data can be batched to account for
 * rapid fire updates that can overwrite each other server side.
 *
 * @param {Array.<string>} spotlightTypeCodeArray Collection of spotlight type codes that are changing
 * @param {GridProductView.SpotlightTypeAction=} opt_SpotlightTypeAction The action to update for; toggle by default
 */
GridProductView.prototype.updateSpotlightTypeData = function (spotlightTypeCodeArray, opt_spotlightTypeAction) {
    this._spotlightTypesView.updateSpotlightTypeData(spotlightTypeCodeArray, opt_spotlightTypeAction);
};

/**
 * Binds actions to the GridProduct
 *
 * @private
 */
GridProductView.prototype._bind = function () {
    const $spotlightTypeButtonContainer = this._spotlightTypesView.getEl();
    $spotlightTypeButtonContainer.on(
        'change',
        $.proxy(function (e) {
            e.stopPropagation(); // do not bubble up
            this._trigger('change', this);
        }, this),
    );

    $spotlightTypeButtonContainer.on(
        'click',
        '.js-append-to-collection',
        $.proxy(function (e) {
            e.preventDefault();
            e.stopPropagation();

            if (!$(e.currentTarget).hasClass('disabled')) {
                if (this.isMerched()) {
                    this.removeFromCollectionSort();
                } else {
                    this.addToCollectionSort();
                }
            }
        }, this),
    );
};

/**
 * Binds actions to the spotlight type pills
 *
 * @private
 */
GridProductView.prototype._bindPills = function () {
    const $pills = this._$el.find('.js-pill');

    $pills.on(
        'pill:remove',
        $.proxy(function (e) {
            e.preventDefault();
            e.stopPropagation();

            const pillInstance = $(e.currentTarget).data('pill');
            this.updateSpotlightTypeData([
                {
                    code: pillInstance.getValue().value,
                    action: SpotlightTypesView.SpotlightTypeAction.REMOVE,
                },
            ]);
        }, this),
    );
};

/**
 * Triggers a new event while passing along any other additional data provided
 *
 * @param {string} type The new event's type
 * @param {Object=} opt_data Additional data to be passed along as an argument to the event handler
 * @private
 */
GridProductView.prototype._trigger = function (type, opt_data) {
    this._$el.trigger($.Event(type), opt_data);
};

export default GridProductView;
