import UriUtils from 'chairisher/util/uri';

const ChairishContext = window.chairisher.context;
const Adwords = ChairishContext.adwords;

/**
 * @returns {string} A URL that is used for registering a user interaction.
 */
export function getRegisterInteractionUrl() {
    return ChairishContext.REGISTER_INTERACTION_URL;
}

/**
 * An interface that provides documentation and access to context variables related to collections.
 */
const TrackingContext = {
    /**
     * @returns {string} The API key to use with Amplitude
     */
    getAmplitudeApiKey() {
        return ChairishContext.AMPLITUDE_API_KEY;
    },

    /**
     * @returns {Object} The global object that stores remarketing data
     */
    getRemarketingObject() {
        return ChairishContext.RemarketingObject;
    },

    /**
     * Caches off the various utm_* params into the Chairish context
     */
    storeUtmParams() {
        ChairishContext.utm = {
            utm_campaign: UriUtils.extractParamFromUri(document.location.search, 'utm_campaign'),
            utm_medium: UriUtils.extractParamFromUri(document.location.search, 'utm_medium'),
            utm_source: UriUtils.extractParamFromUri(document.location.search, 'utm_source'),
        };
    },

    /**
     * @returns {string} The URL hash if any
     */
    getUrlHash() {
        return ChairishContext.urlHash || '';
    },

    /**
     * Stores the URL hash for later use
     */
    storeUrlHash() {
        ChairishContext.urlHash = document.location.hash;
    },

    /**
     * @returns {Object} The various utm_* params stored in the Chairish context
     */
    getUtmParams() {
        return ChairishContext.utm || {};
    },

    /**
     * @returns {string|undefined} The utm_campaign param for the current page, if any
     */
    getUtmCampaign() {
        return TrackingContext.getUtmParams().utm_campaign;
    },

    /**
     * @returns {string|undefined} The utm_medium param for the current page, if any
     */
    getUtmMedium() {
        return TrackingContext.getUtmParams().utm_medium;
    },

    /**
     * @returns {string|undefined} The utm_source param for the current page, if any
     */
    getUtmSource() {
        return TrackingContext.getUtmParams().utm_source;
    },

    /**
     * @returns {string|undefined} The Chairish account id for adwords enhanced conversions
     */
    getAdwordsEnhancedConversionAccount() {
        return ChairishContext.ADWORDS_ENHANCED_CONVERSION_ACCOUNT;
    },

    /**
     * @returns {string|undefined} The label for adwords enhanced conversions for offers
     */
    getAdwordsEnhancedConversionLabelOffer() {
        return ChairishContext.ADWORDS_ENHANCED_CONVERSION_LABEL_OFFER;
    },

    /**
     * @returns {string|undefined} The label for adwords enhanced conversions for orders
     */
    getAdwordsEnhancedConversionLabelOrder() {
        return ChairishContext.ADWORDS_ENHANCED_CONVERSION_LABEL_ORDER;
    },

    /**
     * @returns {string} Label used to track an offer created event in adwords
     */
    getAdwordsOfferCreatedEventLabel() {
        return Adwords.OFFER_CREATED_EVENT_LABEL;
    },

    /**
     * @returns {string} Label used to track an order created event in adwords
     */
    getAdwordsOrderCreatedEventLabel() {
        return Adwords.ORDER_CREATED_EVENT_LABEL;
    },

    /**
     * @returns {number}
     */
    getGoogleConversionId() {
        return ChairishContext.GOOGLE_CONVERSION_ID;
    },

    /**
     * @returns {string}
     */
    getTrackingScreenModifier() {
        return ChairishContext.TRACKING_SCREEN_MODIFIER;
    },

    /**
     * @returns {string}
     */
    getPinterestConversionUrl() {
        return ChairishContext.PINTEREST_CONVERSION_URL;
    },

    /**
     * Purges amplitude test tags to prevent double-tagging.
     */
    purgeAmplitudeTestTags() {
        ChairishContext.AMPLITUDE_TEST_TAGS = [];
    },
};

export default TrackingContext;
