import $ from 'jquery';

import AuthContext from 'chairisher/context/auth';
import BackToTop from 'chairisher/component/backtotop';
import BaseController from 'chairisher/_controller';
import createProductRail from 'chairisher/view/helper/rail';
import FacetSummaryView from 'chairisher/view/search/facetsummary';
import FavoriteProductView from 'chairisher/view/product/favorite';
import HistoryUtils from 'chairisher/util/history';
import MediaQueryUtils from 'chairisher/util/mediaquery';
import MetaUtils from 'chairisher/util/meta';
import ProductContext from 'chairisher/context/product';
import ProductGridView from 'chairisher/view/product/grid';
import SavedSearchForm from 'chairisher/view/savedsearchform';
import {
    getRedirectType,
    trackCollectionScreenView,
    trackPaginationClick,
    trackSearchRefinement,
    trackSearchSorting,
} from 'chairisher/analytics/search';
import SearchView from 'chairisher/view/search';
import updatePromotedListings from 'chairisher/view/promotedlistings/display';
import UriUtils from 'chairisher/util/uri';

import { trackProductList2 } from 'chairisher/analytics/product';
import { getProductIdToProductJsonMap } from 'chairisher/context/collection';
import { isSearchEligibleForPromotedListings } from 'chairisher/context/promotedlistings';
import {
    FacetTagNames,
    getDefaultPageSize,
    getDimensionFilterGroups,
    getFacetGroupings,
    getFacets,
    getNumResults,
    getSearchErrors,
    hasSearchResults,
    isCollectionSearch,
} from 'chairisher/context/search';
import {
    AmplitudeEventProperty,
    CollectionPosition,
    GlobalPosition,
    ProductRailType,
    SearchPosition,
    logAmplitudeButtonClickEvent,
    logAmplitudeLinkClickEvent,
    logScreenView,
    TrackingScreen,
} from 'chairisher/util/analytics';

/**
 * Controller that binds actions for search-related pages
 */
class SearchController extends BaseController {
    constructor() {
        super();
        /**
         * The number of results.
         *
         * @type {number|null}
         */
        this.numResults = null;

        /**
         * The searchView instance used by the controller.
         *
         * @type {SearchView}
         */
        this.searchView = new SearchView({
            defaultPageSize: getDefaultPageSize(),
            dimensionData: getDimensionFilterGroups(),
            facets: getFacets(),
            facetGroupings: getFacetGroupings(),
            isAccordion: MediaQueryUtils.isMaxMediumDesktop(),
            searchErrors: getSearchErrors(),
        });

        /**
         * Product grid view to use with folders and the merch tool
         *
         * @type {ProductGridView}
         */
        this.productGrid = new ProductGridView({
            $el: $('.js-search-results .js-product-grid'),
            position: CollectionPosition.MAIN_PRODUCT_GRID,
        });
    }

    /**
     * The number of search results
     *
     * @returns {number}
     */
    getNumResults() {
        return this.numResults === null ? getNumResults() : this.numResults;
    }

    /**
     * Binds functionality to the facet summary component
     */
    bindFacetSummary() {
        const facetSummaryView = new FacetSummaryView();

        const $facetSummaryContainer = $('.js-facet-summary-container');
        const $facetSummary = $('.js-selected-facet-container');

        //
        // show hide facets
        $facetSummaryContainer.on('click', '.js-btn-filter', (e) => {
            e.preventDefault();

            const $window = $(window);
            const searchFacetNamespace = 'searchfacets';

            this.searchView.showFacets();

            // if someone resizes their screen to be tablet sized we need to handle
            // the case where they make selections but do not submit and then resize
            // their screen to be bigger than tablet
            $window.smartresize(() => {
                if (MediaQueryUtils.isMinLargeDesktop()) {
                    this.searchView.hideFacets();
                    $window.off(`resize.${searchFacetNamespace}`);
                }
            }, searchFacetNamespace);
        });

        //
        // clear facets
        $facetSummary.on('click', '.js-btn-clear', (e) => {
            e.preventDefault();
            facetSummaryView.clear();

            this.searchView.clearState();
            this.searchView.updateFormSelections();
            this.searchView.updateFacetVisibility();

            this.submit(false, true);
        });

        //
        // reset facets
        $('.js-btn-reset').on('click', (e) => {
            e.preventDefault();
            facetSummaryView.clear();

            this.searchView.clearCustomInputs();
            this.searchView.clearState();
            this.searchView.updateFormSelections();
            this.searchView.updateFacetVisibility();

            this.searchView.submit(true);
        });

        //
        // Remove Facet Tag
        $(facetSummaryView.getContainerSelector()).on('click', '.js-search-facet-pill', (e) => {
            e.preventDefault();
            const $tag = $(e.currentTarget);
            const name = $tag.attr('data-facet-name');
            const value = $tag.attr('data-facet-value');
            // Note: `removeFacetTag` triggers the `change` event on the input and
            //       the view / form state will be handled by the callback bound to
            //       the `change` event for the `input` (e.currentTarget)
            facetSummaryView.removeFacetTag(name, value, true);
        });
    }

    /** @inheritDoc */
    bindPageActions() {
        super.bindPageActions();

        this.productGrid.setGridProductViews(getProductIdToProductJsonMap() || {});

        // Submit facets when view is accordion
        $('.js-btn-done').on('click', (e) => {
            e.preventDefault();

            this.searchView.hideFacetForm();

            this.searchView
                .submit(true)
                .done(() => {
                    this.updatePromotedListings();
                })
                .always(() => {
                    this.searchView.hideFacetBackdrop();
                });
        });

        this.bindSearchForm();

        if (HistoryUtils.hasPushState()) {
            // Modern browsers make use of a Back-Forward Cache (BFCache) that saves the DOM for pages they deem
            // important. After a page has been cached using the BFCache, when the user presses the back
            // button the old page's DOM will be loaded and none of the Javascript will be executed. However, the
            // `pageshow` event will be triggered and Javascript bound to this event will be executed.
            //
            // @see https://webkit.org/blog/427/webkit-page-cache-i-the-basics/
            $(window).on('pageshow, popstate', (e) => {
                // Note: since we're binding pageshow and popstate with jQuery we need to
                // look to e.originalEvent for the state object since it's omitted in the jQuery event
                if (e.originalEvent.state) {
                    this.searchView.setStateObject(e.originalEvent.state);

                    if (this.searchView.getSearchForm().length) {
                        this.searchView.updateFormSelections();
                        this.searchView.updateFacetVisibility();

                        this.searchView.displayFacetTags();
                        this.submit(false, false).always(() => this.searchView.restoreScrollPosition());
                    } else {
                        window.location.href = this.searchView.getStateUrl();
                    }

                    if (!document.location.pathname.startsWith('/search')) {
                        MetaUtils.restoreRobotsMetaTag();
                    }
                }
            });
        }

        this.bindFacetSummary();
        this.bindSavedSearchButtons();

        $(window).smartresize(() => {
            const shouldBeAccordion = MediaQueryUtils.isMaxMediumDesktop();

            if (this.searchView.isAccordion() === false && shouldBeAccordion) {
                const $facets = this.searchView.getSearchForm().find('.js-facet');
                $facets.each((_, el) => {
                    const $facet = $(el);

                    this.searchView.toggleRefinementVisibility(
                        $facet.find('.js-facet-title'),
                        $facet.find('.js-facet-choices'),
                        false,
                    );
                });
            }

            this.searchView.setIsAccordion(shouldBeAccordion);
        });

        BackToTop.bind();

        this.productGrid.renderFolderTrigger();

        // track clicks on top pagination
        $('.js-pager-container-truncated a').on('click', (e) => {
            trackPaginationClick(e, SearchPosition.TOP_PAGINATION);
        });

        // track clicks on bottom pagination
        $('.js-pager-container-full a').on('click', (e) => {
            trackPaginationClick(e, SearchPosition.BOTTOM_PAGINATION);
        });

        this.updatePromotedListings();
    }

    /** @inheritdoc * */
    bindAdminActions() {
        super.bindAdminActions();

        if (AuthContext.canAccessMerchTool() && hasSearchResults()) {
            import('chairisher/view/admin/merchtool').then(({ default: MerchToolView }) => {
                this.merchTool = new MerchToolView({
                    $el: $($('#js-merch-tool-template').html()),
                    productGridView: this.productGrid,
                });
            });
        }
    }

    /** @inheritDoc */
    bindChairishActions() {
        super.bindChairishActions();

        // add recently viewed rail
        const rvProductsIdSelector = '#js-recently-viewed';
        createProductRail({
            $containerEl: $(rvProductsIdSelector),
            $el: $(`${rvProductsIdSelector}-scrollable-grid`),
            analyticsTrackingType: ProductRailType.RECENTLY_VIEWED,
            listSelector: rvProductsIdSelector,
            railUrl: ProductContext.getRecentlyViewedUrl(),
        });

        $('.js-layout-not-found, .js-layout-few-results').on(
            'click',
            '.js-cta-save-search, .js-cta-remove-search',
            (e) => {
                const savedSearchForm = new SavedSearchForm({
                    buttonContainerSelector: '.js-save-search-text, .js-remove-search-text',
                    buttonSelectorFollow: '.js-cta-save-search',
                    buttonSelectorUnfollow: '.js-cta-remove-search',
                });
                if ($(e.currentTarget).hasClass('js-cta-save-search')) {
                    savedSearchForm.follow();
                } else {
                    savedSearchForm.unfollow();
                }
            },
        );

        if (!MediaQueryUtils.isTouchDevice()) {
            $('.js-async-btn-saved-search').popover({
                placement: 'bottom',
                selector: '.js-cta-save-search, .js-cta-remove-search',
                trigger: 'hover contextmenu',
            });
        }
    }

    /** @inheritDoc */
    bindPageAnalytics() {
        super.bindPageAnalytics();

        trackProductList2({
            listSelector: '.js-product-grid',
        });

        $('.js-breadcrumb').on('click', 'a', (e) => {
            const $currentTarget = $(e.currentTarget);

            logAmplitudeLinkClickEvent($currentTarget.text(), $currentTarget.attr('href'), GlobalPosition.BREADCRUMBS);
        });

        this.bindScreenAnalytics();
    }

    /**
     * Splits out the call to track the `screen - <screen name>` event because this class gets
     * inherited by other controllers like CollectionController, which need to run different
     * screen analytics calls
     */
    bindScreenAnalytics() {
        if (isCollectionSearch()) {
            trackCollectionScreenView();
        } else {
            const eventProps = {
                [AmplitudeEventProperty.NUM_RESULTS]: this.getNumResults(),
                [AmplitudeEventProperty.REDIRECT_TYPE]: getRedirectType(),
            };

            const queryTerm = UriUtils.extractParamFromUri(document.location.search, 'q');
            if (queryTerm) {
                eventProps[AmplitudeEventProperty.QUERY] = queryTerm;
                eventProps[AmplitudeEventProperty.NUM_TOKENS] = queryTerm.trim().split(' ').length;
            }

            logScreenView(TrackingScreen.SEARCH_RESULTS, eventProps);
        }
    }

    /**
     * Binds functionality to saved search buttons
     */
    bindSavedSearchButtons() {
        // bind saved search buttons
        const savedSearchForm = new SavedSearchForm();

        const $asyncSavedSearchButtonContainer = $('.js-async-btn-saved-search, .js-layout-not-found');

        $asyncSavedSearchButtonContainer.on('click', '.js-btn-save-search', (e) => {
            e.preventDefault();
            const $btn = $(e.currentTarget);

            savedSearchForm.follow();

            logAmplitudeButtonClickEvent(`${$btn.text().trim()} (${$btn.parent().data('button-position')})`);
        });

        $asyncSavedSearchButtonContainer.on('click', '.js-btn-remove-search', (e) => {
            e.preventDefault();
            savedSearchForm.unfollow();
        });

        // bind saved search button popovers
        const $asyncContainer = $('.js-async-btn-saved-search');

        $asyncContainer.on('mouseenter mouseleave', '.js-btn-save-search', function () {
            const $btnSavedSearch = $(this);

            // if there is new text to swap on hover, swap it
            // this should only be applicable for searches that have been saved
            const oldText = $btnSavedSearch.text();
            const newText = $btnSavedSearch.data('toggle-text') || oldText;

            if (newText !== oldText) {
                $btnSavedSearch.text(newText).data('toggle-text', oldText);
            }
        });
    }

    /**
     * Binds actions that are related to the submission of the search form
     */
    bindSearchForm() {
        const $searchForm = this.searchView.getSearchForm();
        $searchForm.on(
            'change',
            'input:not(.js-custom-facet-input, .js-dimension-unit-input), select',
            (e, wasFacetTagRemoved) => {
                this.handleSubmit(e.currentTarget, wasFacetTagRemoved);
            },
        );

        $searchForm.find('.js-autocomplete-query').on('autocomplete:selected', (e) => {
            this.handleSubmit(e.currentTarget);
        });

        // this only affects collection templates (see webapp/templates/collection/search.html)
        $('#js-facet-sort').on('change', 'select', (e) => {
            this.handleSubmit(e.currentTarget, false);
        });

        // clear facets
        $searchForm.on('click', '.js-facet .js-clear-all', (e) => {
            e.preventDefault();

            const $facet = $(e.currentTarget).closest('.js-facet');

            if (!$facet.hasClass('has-selections')) {
                return;
            }

            $facet.find('.js-custom-facet-input').val('');

            // do not persist on tablet and smaller screen sizes so
            // we can batch the search submission into one big request
            if (MediaQueryUtils.isMaxMediumDesktop()) {
                this.searchView.markDirty();
            }

            const facetKey = $facet.data('key');
            this.searchView.uncheckFacet(facetKey, !this.searchView.isDirty());

            if (!this.searchView.isDirty()) {
                this.submit();
            }
        });

        $searchForm.on('click', '.js-load-more', (e) => {
            e.preventDefault();
            const $facet = $(e.currentTarget).closest('.js-facet');
            this.searchView.uncollapseFacet($facet);
        });

        //
        // change # of products per page
        $('.js-page-size').on('change', (e) => {
            e.preventDefault();
            this.searchView.setPageSize(parseInt($(e.currentTarget).find('[name=page_size]').val(), 10));
            this.handleSubmit(e.currentTarget);
        });
    }

    /**
     * Handles submitting data to the server when a domElement changes.
     *
     * @param {HTMLElement} domElement The element which just changed.
     * @param {boolean=} wasFacetTagRemoved Indicates that the change was a result of a facet tag being removed.
     */
    handleSubmit(domElement, wasFacetTagRemoved = false) {
        // do not persist on tablet and smaller screen sizes so
        // we can batch the search submission into one big request
        if (MediaQueryUtils.isMaxMediumDesktop() && !wasFacetTagRemoved) {
            this.searchView.markDirty();
            this.searchView.changeSelection(domElement, wasFacetTagRemoved);

            if (
                domElement.name === FacetTagNames.LOCATION ||
                (domElement.checked && this.searchView.facetBuilder.isLocationRequired(domElement.value))
            ) {
                // Validate state when location dependent facet is checked
                this.searchView.validateState();
            }
        } else {
            this.searchView.changeSelection(domElement, wasFacetTagRemoved);
        }

        // Submit the data after updating the ui so it feels snappier.
        //
        // Embedding the actions above this line into the .done() promise
        // callback makes selection feel really sluggish.
        if (!this.searchView.isDirty()) {
            this.submit(true);
        }

        const elementName = domElement.name;
        let elementValue = domElement.value;
        let stateVal = this.searchView.getStateVal(elementName);

        if (elementName === FacetTagNames.SHIPPING_OPTIONS) {
            // Use human-readable value
            elementValue = $(domElement).closest('.js-facet-choice-label').data('display-string');
            stateVal = elementValue;
        }

        // track the changing inputs
        if (elementName === 'sort') {
            trackSearchSorting(elementValue);
        } else if ((stateVal || domElement.checked) && this.searchView.isFieldCustomRange(elementName)) {
            const $facetChoices = $(domElement).closest('.js-facet-choices');
            let position = null;
            $facetChoices.find('.js-facet-choice').each((i, choice) => {
                if ($(choice).find(`[type="checkbox"][value="${elementValue}"]`).length === 1) {
                    position = (i + 1).toString();
                }
                return position === null; // return false when position is set to end loop early.
            });

            // If the position is not found, then the user typed something in a custom range boxes.
            // For example, the price facet has both preset buckets and a custom range input.
            // The 'custom' string allows us to differentiate between them.
            trackSearchRefinement(elementName, elementValue, stateVal, position || 'custom');
        } else if (domElement.checked || elementName === 'location') {
            trackSearchRefinement(elementName, elementValue, stateVal);
        }
    }

    /**
     * Submit data to the server and perform various callbacks
     *
     * @param {boolean=} shouldResize Whether to bind the screen for resizing on submit
     * @param {boolean=} shouldPersist Whether to persist history state on submit
     * @param {boolean=} shouldBindScreenAnalytics Whether to bind analytics for the screen
     * @return {$.Deferred}
     */
    submit(shouldResize, shouldPersist, shouldBindScreenAnalytics) {
        return this.searchView.submit(shouldPersist).done((data) => {
            // track the products being loaded

            const productIdToProductObjMap = data.product_id_to_product_json || {};
            ProductContext.setProductIdToProductObjMap(productIdToProductObjMap);

            this.updatePromotedListings();

            // if the search results have been filtered AND a product grid JS object is
            // present for staff (typically merchandisers), repopulate the JS object with
            // new data, and render it appropriately. No need to rebind as events are bound to
            // the product grid via delegate targets
            if (this.productGrid && hasSearchResults()) {
                this.productGrid.setGridProductViews(productIdToProductObjMap);

                if (AuthContext.canAccessMerchTool()) {
                    this.productGrid.renderSpotlightTypesButtons();
                    this.productGrid.renderSpotlightTypesTag();
                }

                if (this.merchTool && this.merchTool.isVisible() && AuthContext.canAccessMerchTool()) {
                    this.productGrid.disableSpotlightTypeButtons();
                    this.productGrid.renderSpotlightTypePills();
                } else {
                    FavoriteProductView.updateFavoriteProductIds().done(() => {
                        FavoriteProductView.drawButtons();
                        this.productGrid.renderFolderTrigger();
                    });
                }

                this.productGrid.trigger('change');
            }

            if (shouldResize) {
                BackToTop.bind();
            }

            if (shouldBindScreenAnalytics) {
                this.numResults = data.num_items;
                this.bindScreenAnalytics();
            }

            // removes all potential 'page-size-n' classes from the element, as these are only used to establish
            // a min-height placeholder for the product-grid prior to the actual products being loaded. once loaded,
            // the 'page-size-n' class needs to be removed to avoid any height differentials and inadvertant whitespace.
            this.productGrid.getEl().removeClass('page-size-24 page-size-48 page-size-96');
        });
    }

    /**
     * Helps update promoted listings and track the data
     *
     * @returns {$.Deferred}
     */
    updatePromotedListings() {
        const numProductsOnPage = $('.js-product-grid .js-product').length;
        const numProductsBetweenPromotedListings = 12;
        const numPromotedListingsPerRow = 3;
        const numRequested =
            Math.floor(numProductsOnPage / numProductsBetweenPromotedListings) * numPromotedListingsPerRow;

        if (!isSearchEligibleForPromotedListings() || !numRequested) {
            return $.Deferred().resolve();
        }

        return updatePromotedListings('.js-search-results .js-product', numRequested).done((data) => {
            $('.js-product-grid').trigger('product.grid.change');

            const productIds = (data.responseData.products || []).map(({ id }) => id);
            if (productIds.length && AuthContext.isAuthenticated()) {
                $.ajax({
                    url: ProductContext.getFavoriteLookupUrl(),
                    data: {
                        product_ids: productIds.join(','),
                    },
                }).done((folderData) => {
                    folderData.action_info.forEach((productData) => {
                        const productId = productData.id;

                        ProductContext.updateFolderedProductId(productId, productData.is_in_folder);
                        this.productGrid.addGridProductView(productData.id, productData);

                        if (productData.is_favorited || productData.is_in_folder) {
                            this.productGrid.getGridProductView(productData.id).renderFolderTrigger();
                        }
                    });
                });
            }
        });
    }
}

export default SearchController;
