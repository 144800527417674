import $ from 'jquery';
import ChairishABTest from 'chairisher/abtest';
import { getUrlName } from 'chairisher/context/site';

/**
 * Name of the AB Test, which should match the cookie key serverside
 */
const EXPERIMENT_NAME = 'SHIPPING_MARGIN_AB_TEST';

/**
 * AB Test for determining if increasing the shipping costs has a negative impact on conversion,
 **
 * TODO: (CHAIR-17376) Delete file after AB test
 */
class ShippingMarginTest extends ChairishABTest {
    constructor(settings) {
        super(settings);
        this.name = EXPERIMENT_NAME;
    }

    /**
     * @returns {string}
     */
    static getExperimentName() {
        return EXPERIMENT_NAME;
    }

    control() {
        if (getUrlName() === 'product_detail') {
            $('.js-variant-shipping-detail').remove();
        }
    }

    variation() {
        if (getUrlName() === 'product_detail') {
            const $shippingSection = $('.js-shipping-product-info');

            $('.js-shipping-detail').remove();
            $shippingSection.removeClass('product-info');
            $('#js-info-shipping').addClass('shipping-product-info');
            $shippingSection
                .find('.js-section-title-btn')
                .addClass('btn-link js-shipping-section-btn shipping-section-btn')
                .removeClass('section-title');
            $shippingSection.find('.js-icon-is-open').remove();
            $shippingSection.find('.js-icon-is-closed').remove();
            $('.js-message-user-container').addClass('variant-message-user-container');
        }
    }
}

export default ShippingMarginTest;
