import $ from 'jquery';

import AppleView from 'chairisher/view/apple';
import AuthenticationView from 'chairisher/view/authentication';
import CookieUtils from 'chairisher/util/cookie';
import FacebookView from 'chairisher/view/facebook';
import FavoriteProductView from 'chairisher/view/product/favorite';
import UriUtils from 'chairisher/util/uri';

import {
    trackAccountCreated,
    trackSuccessfulAuthorizationForAction,
    trackUnauthorizedAction,
    trackUnsuccessfulAuthorizationForAction,
} from 'chairisher/analytics/auth';
import { trackAccountCreatedForPRT } from 'chairisher/thirdparty/pinterestretargeting';
import { isUnauthorized } from 'chairisher/util/status';

/**
 * Automatically include the CSRF header in jQuery XHR.
 *
 * @param {string} method The HTTP method being used
 * @returns {boolean} True indicates the HTTP method does not need CSRF protection
 */
function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}

$.ajaxSetup({
    beforeSend(xhr, settings) {
        if (!csrfSafeMethod(settings.type)) {
            const csrfToken = CookieUtils.getValueFromCookie('csrftoken');
            xhr.setRequestHeader('X-CSRFToken', csrfToken);

            if (this.data && $.isFunction(this.data.split)) {
                const dataParts = this.data.split('&');
                for (let i = 0; i < dataParts.length; i++) {
                    if (dataParts[i].indexOf('csrfmiddlewaretoken=') !== -1) {
                        dataParts[i] = `csrfmiddlewaretoken=${csrfToken}`;
                    }
                }

                this.data = dataParts.join('&');
            }
        }
    },
});

//
// $.ajaxPrefilter is used to handle/modify options before the request is processed by $.ajax.
// http://api.jquery.com/jquery.ajaxprefilter/
$.ajaxPrefilter((options, originalOptions, jqXHR) => {
    // Don't do anything if the authUrl custom option is not
    // included in the options passed to $.ajax. This prevents
    // the authentication modal from being bound infinitely
    if (!originalOptions.authUrl) {
        return;
    }

    // Declare a new deferred object to handle fail callbacks
    const $deferred = $.Deferred();

    // Return normally if the request works. Since $.ajaxPrefilter is
    // called before $.ajax returns, this .done will be called before
    // any other .done that is added elsewhere. Below the XHR object is
    // added as a promise to $deferred, so the original will be called
    // as soon as $deferred is resolved.
    jqXHR.done($deferred.resolve);

    // Inject this callback before others to ensure the account is authenticated.
    jqXHR.fail(function () {
        const args = Array.prototype.slice.call(arguments);

        // If the error is not related to authentication, reject $deferred
        // and call other registered .fail callbacks registered w/the original args
        if (!isUnauthorized(jqXHR.status)) {
            $deferred.rejectWith(jqXHR, args);
        } else {
            // The user is not authenticated so display the appropriate
            // AccountQuickCreateView. The .done callback registered by the code
            // that made the original $.ajax call, will not be called until
            // $deferred is resolved
            const authView = new AuthenticationView({
                formSelector: '.js-auth-quick',
            });
            authView
                .display({
                    url: options.authUrl,
                })
                .done(() => {
                    const variation = UriUtils.extractParamFromUri(options.authUrl, 'v') || 'unknown';

                    AppleView.loadAuthButton(); // for apple sign in button
                    FacebookView.bind(); // for facebook connect login button

                    trackUnauthorizedAction(variation);

                    const $modal = $(authView.getModalSelector());

                    $modal.on('submit', 'form', function (e) {
                        e.preventDefault();
                        const $form = $(this);
                        $form.find('.btn-default').prop('disabled', true);

                        authView.submit(
                            $form.attr('action'),
                            $form.serialize(),
                            (data) => {
                                authView.hide();

                                if (data.was_account_created) {
                                    trackAccountCreatedForPRT();
                                    trackAccountCreated();
                                }

                                trackSuccessfulAuthorizationForAction(variation);

                                FavoriteProductView.updateFavoriteProductIds().done(() => {
                                    FavoriteProductView.drawButtons();
                                });
                                FavoriteProductView.updateFavoriteCount(data.num_favorites);

                                // Resend the original $.ajax request. On success, resolve
                                // $deferred so the original .done is called. On failure reject
                                // $deferred so the original .fail is called. Since options still
                                // contains the custom authUrl option, if the user is still not
                                // authenticated for whatever reason, $.ajaxPrefilter will handle it
                                $.ajax(options).then($deferred.resolve, $deferred.reject);
                            },
                            (data) => {
                                trackUnsuccessfulAuthorizationForAction(variation);

                                // If the authentication form is submitted and comes back with a 4XX or 5XX
                                // status code, inject the returned HTML with errors. Since $.deferred
                                // has not been rejected or resolved yet, there's no danger of the original
                                // .done or .fail being called
                                authView.setFormHtml(data.authentication_form_html);

                                // re-bind the apple and facebook button
                                AppleView.loadAuthButton();
                                FacebookView.bind();

                                // re-enable submit button if failure
                                $form.find('.btn-default').prop('disabled', false);
                            },
                        );
                    });

                    // If the modal is dismissed without authenticating,
                    // reject $deferred so the original .fail can be called
                    $modal.on('hidden.bs.modal', () => {
                        if ($deferred.state() === 'pending' && !authView.didAttemptAuthentication()) {
                            // only execute this code if the deferred object is neither resolved nor rejected
                            delete jqXHR.status; // remove the status so we don't get duplicative errors
                            $deferred.rejectWith(jqXHR, args);
                            trackUnsuccessfulAuthorizationForAction(variation);
                        }
                    });
                });
        }
    });

    // Prevent jqXHR's promise callbacks from being executed
    // until $deferred's have been resolved or rejected
    return $deferred.promise(jqXHR);
});

//
// jQuery Plugins
//

/**
 * Throttles execution of a callback on rapidly fired events
 *
 * @param {Function} callback
 * @returns {Function}
 */
function throttle(callback) {
    let isRunning = false;

    return function throttled() {
        const args = arguments;

        if (isRunning) {
            return;
        }

        isRunning = true;

        window.requestAnimationFrame(function () {
            callback.apply(this, args);
            isRunning = false;
        });
    };
}

/**
 * Executes a function on resize, but on a much more throttled basis
 *
 * @param {Function} fn The function to execute
 * @param {string} opt_eventNamespace The namespace to add to the resize event
 * @returns {*}
 */
$.fn.smartresize = function (fn, opt_eventNamespace) {
    const eventName = opt_eventNamespace ? `resize.${opt_eventNamespace}` : 'resize';

    if (fn) {
        return this.on(eventName, throttle(fn));
    }
    this.trigger(eventName);
};

/**
 * Executes a function on scroll, but on a much more throttled basis
 *
 * @param {Function} fn The function to execute
 * @param {string} opt_eventNamespace The namespace to add to the scroll event
 * @returns {*}
 */
$.fn.smartscroll = function (fn, opt_eventNamespace) {
    const eventName = opt_eventNamespace ? `scroll.${opt_eventNamespace}` : 'scroll';

    if (fn) {
        return this.on(eventName, throttle(fn));
    }
    this.trigger(eventName);
};

/**
 * A simple jQuery plugin to warn a user of unsaved modifications to a form. Can be called multiple times without
 * issue since we unbind before binding.
 *
 * @example:
 * // activates unsaved content modal
 * $('#myForm').warnOnUnload()
 */
(function () {
    let shouldWarn = false;

    $(window).on('beforeunload', (e) => {
        if (!shouldWarn) {
            return;
        }

        e.preventDefault();

        return '';
    });

    /**
     * Responds to a form change.
     */
    const handleFormChange = function (e) {
        shouldWarn = true;
    };

    /**
     * Responds to a form submission attempt by disabling any warnings.
     * Note that this is not dependent on which form changed.
     */
    const handleFormSubmit = function (e) {
        shouldWarn = false;
    };

    $.fn.warnOnUnload = function () {
        const form = $(this);

        shouldWarn = false;

        // Detect the first change.
        form.off('change.warnOnUnload').one('change.warnOnUnload', handleFormChange);

        // Detect submissions.
        form.off('submit.warnOnUnload').on('submit.warnOnUnload', handleFormSubmit);

        return form;
    };
})();

/**
 * A jQuery plugin to automatically invoke a disabled loading state on a form's
 * buttons on submit.
 *
 * @requires bootstrap-button.js
 */
(function () {
    const getAllLoadingButtons = function (parent) {
        return parent.find('.btn[data-loading-text]');
    };

    /**
     * Responds to form submission by setting the loading state of all buttons
     * with explicit loading text.
     */
    const handleFormSubmit = function (e) {
        // TODO(nate): How can we enforce this handler to be called last?
        if (!e.isDefaultPrevented()) {
            disableAll($(e.target));
        }
    };

    var disableAll = function (form) {
        const loadingButtons = getAllLoadingButtons(form);
        loadingButtons.button('loading');
    };

    const resetAll = function (form) {
        const loadingButtons = getAllLoadingButtons(form);
        loadingButtons.button('reset');
    };

    $.fn.buttonLoadingHelper = function () {
        const form = $(this);

        // Detect submissions.
        form.on('submit', handleFormSubmit);

        return form;
    };

    $.fn.disableLoadingButtons = function () {
        const form = $(this);

        disableAll(form);

        return form;
    };

    $.fn.resetLoadingButtons = function () {
        const form = $(this);

        resetAll(form);

        return form;
    };
})();
